import { useEffect, useRef, useState } from "react";

import "./chat.css";
import amouranth from "./amouranth.png";

function App() {
    const [girl] = useState({ name: "Amouranth", key: "amouranth" });
    const userImage = null;
    //"https://cdn.discordapp.com/avatars/267346030859780108/bd8c0d93ff9cfb65bbd7842959021447.webp?size=160"

    return (
        <>
            <div className="background-green" />

            <div className="main-container">
                <div className="left-container">
                    <ChatHeader userImage={userImage} />
                    <NotificationBox />

                    <SearchBar />

                    <ConversationList />
                </div>

                <ActiveConversation girl={girl} />
            </div>
        </>
    );
}

interface MessageData {
    type: "in" | "out";
    text: string;
    timestamp: string;
}

interface Girl {
    name: string;
    key: string;
}

const DefaultProfilePic = () => {
    return (
        <svg
            viewBox="0 0 212 212"
            height="45"
            width="45"
            preserveAspectRatio="xMidYMid meet"
            className="ln8gz9je ppled2lx"
            version="1.1"
            x="0px"
            y="0px"
            enable-background="new 0 0 212 212"
        >
            <path
                fill="#DFE5E7"
                className="background"
                d="M106.251,0.5C164.653,0.5,212,47.846,212,106.25S164.653,212,106.25,212C47.846,212,0.5,164.654,0.5,106.25 S47.846,0.5,106.251,0.5z"
            ></path>
            <g>
                <path
                    fill="#FFFFFF"
                    className="primary"
                    d="M173.561,171.615c-0.601-0.915-1.287-1.907-2.065-2.955c-0.777-1.049-1.645-2.155-2.608-3.299 c-0.964-1.144-2.024-2.326-3.184-3.527c-1.741-1.802-3.71-3.646-5.924-5.47c-2.952-2.431-6.339-4.824-10.204-7.026 c-1.877-1.07-3.873-2.092-5.98-3.055c-0.062-0.028-0.118-0.059-0.18-0.087c-9.792-4.44-22.106-7.529-37.416-7.529 s-27.624,3.089-37.416,7.529c-0.338,0.153-0.653,0.318-0.985,0.474c-1.431,0.674-2.806,1.376-4.128,2.101 c-0.716,0.393-1.417,0.792-2.101,1.197c-3.421,2.027-6.475,4.191-9.15,6.395c-2.213,1.823-4.182,3.668-5.924,5.47 c-1.161,1.201-2.22,2.384-3.184,3.527c-0.964,1.144-1.832,2.25-2.609,3.299c-0.778,1.049-1.464,2.04-2.065,2.955 c-0.557,0.848-1.033,1.622-1.447,2.324c-0.033,0.056-0.073,0.119-0.104,0.174c-0.435,0.744-0.79,1.392-1.07,1.926 c-0.559,1.068-0.818,1.678-0.818,1.678v0.398c18.285,17.927,43.322,28.985,70.945,28.985c27.678,0,52.761-11.103,71.055-29.095 v-0.289c0,0-0.619-1.45-1.992-3.778C174.594,173.238,174.117,172.463,173.561,171.615z"
                ></path>
                <path
                    fill="#FFFFFF"
                    className="primary"
                    d="M106.002,125.5c2.645,0,5.212-0.253,7.68-0.737c1.234-0.242,2.443-0.542,3.624-0.896 c1.772-0.532,3.482-1.188,5.12-1.958c2.184-1.027,4.242-2.258,6.15-3.67c2.863-2.119,5.39-4.646,7.509-7.509 c0.706-0.954,1.367-1.945,1.98-2.971c0.919-1.539,1.729-3.155,2.422-4.84c0.462-1.123,0.872-2.277,1.226-3.458 c0.177-0.591,0.341-1.188,0.49-1.792c0.299-1.208,0.542-2.443,0.725-3.701c0.275-1.887,0.417-3.827,0.417-5.811 c0-1.984-0.142-3.925-0.417-5.811c-0.184-1.258-0.426-2.493-0.725-3.701c-0.15-0.604-0.313-1.202-0.49-1.793 c-0.354-1.181-0.764-2.335-1.226-3.458c-0.693-1.685-1.504-3.301-2.422-4.84c-0.613-1.026-1.274-2.017-1.98-2.971 c-2.119-2.863-4.646-5.39-7.509-7.509c-1.909-1.412-3.966-2.643-6.15-3.67c-1.638-0.77-3.348-1.426-5.12-1.958 c-1.181-0.355-2.39-0.655-3.624-0.896c-2.468-0.484-5.035-0.737-7.68-0.737c-21.162,0-37.345,16.183-37.345,37.345 C68.657,109.317,84.84,125.5,106.002,125.5z"
                ></path>
            </g>
        </svg>
    );
};

const ActiveConversation = (props: { girl: Girl }) => {
    const { girl } = props;

    const [messages, setMessages] = useState([] as MessageData[]);
    const [sending, setSending] = useState(false);

    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const sendMessage = async (message: string, girl: string) => {
        setSending(true);

        // Form the chat history
        const history = messages.map((msg) => ({
            role: msg.type === "in" ? "assistant" : "user",
            content: msg.text,
        }));

        setMessages((current) => [...current, { text: message, type: "out", timestamp: "07:59" }]);

        try {
            const resp = await fetch(new URL("https://girls-api.bitgate.workers.dev/api/v1/chat?girl=" + girl), {
                method: "POST",
                body: JSON.stringify({ message, history }),
                headers: { "Content-Type": "application/json" },
            });

            const json = await resp.json();
            const text: string = json.choices[0].message.content;

            // Split out the individual replies into bubbles, removing empty lines
            const bubbles = text
                .split("\n")
                .filter((b) => b.length)
                .map((b) => ({ text: b.replace(/^(.*?):\s*/, "").trim(), type: "in" as const, timestamp: "07:59" }));

            setMessages((current) => [...current, ...bubbles]);
        } catch (e) {
            console.error(e);
        }

        setSending(false);
    };

    // Dynamically form the chat history
    let lastDirection = "";

    const typeLookup = {
        in: "friend-message",
        out: "my-message",
    };

    const chats = messages.map((msg) => {
        const classes = "message-box " + (msg.type === lastDirection ? "" : "initial") + " " + typeLookup[msg.type];
        lastDirection = msg.type;

        return (
            <div className={classes} key={msg.type + ":" + msg.text}>
                <p>
                    {msg.text} <span>{msg.timestamp}</span>
                </p>
            </div>
        );
    });

    useEffect(scrollToBottom, [chats]);

    return (
        <div className="right-container">
            <div className="header">
                <div className="img-text">
                    <div className="user-img">
                        <img className="dp" src={amouranth} alt="" />
                    </div>
                    <h4>
                        {girl.name} <br /> <span>{sending ? "typing..." : "Online"}</span>
                    </h4>
                </div>

                <ConversationActions />
            </div>

            <div className="chat-container">
                {chats}
                <div ref={messagesEndRef} />
            </div>

            <div className="chatbox-input">
                <i className="fa-regular fa-face-grin"></i>
                <i className="fa-sharp fa-solid fa-paperclip"></i>

                <form
                    onSubmitCapture={(ev) => {
                        ev.preventDefault();
                        sendMessage(ev.target[0].value, girl.key);
                        ev.target[0].value = "";
                    }}
                >
                    <input type="text" placeholder="Type a message" readOnly={sending} />
                </form>

                <i className="fa-solid fa-paper-plane"></i>
            </div>
        </div>
    );
};

const ConversationActions = () => {
    return (
        <div className="nav-icons">
            <li>
                <i className="fa-solid fa-magnifying-glass"></i>
            </li>
            <li>
                <i className="fa-solid fa-ellipsis-vertical"></i>
            </li>
        </div>
    );
};

const ConversationList = () => {
    const girls = [
        {
            name: "Amouranth",
            key: "amouranth",
            image: amouranth,
            lastMessage: "Damn ur dick is big 😳😳",
            active: true,
            numUnread: 0,
        },
        {
            name: "Belle",
            key: "belle",
            image: "https://randomuser.me/api/portraits/women/72.jpg",
            lastMessage: "lol wow that was way too...",
            active: false,
            numUnread: 4,
        },
    ];

    return (
        <div className="chat-list">
            {girls.map((girl) => (
                <div key={girl.key} className={girl.active ? "chat-box active" : "chat-box"}>
                    <div className="img-box">
                        <img className="img-cover" src={girl.image} alt="" />
                    </div>
                    <div className="chat-details">
                        <div className="text-head">
                            <h4>{girl.name}</h4>
                            <p className={girl.numUnread ? "time unread" : "time"}>07:49</p>
                        </div>
                        <div className="text-message">
                            <p>{girl.lastMessage}</p>
                            {girl.numUnread > 0 ? <b>{girl.numUnread}</b> : null}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

const ChatHeader = ({ userImage }) => {
    return (
        <div className="header">
            <div className="user-img">
                {userImage ? <img className="dp" src={userImage} alt="" /> : <DefaultProfilePic />}
            </div>

            <div className="nav-icons">
                <li>
                    <i className="fa-solid fa-coins"></i>
                </li>
                <li>
                    <i className="fa-solid fa-heart"></i>
                </li>
                <li>
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                </li>
            </div>
        </div>
    );
};

const NotificationBox = () => {
    return (
        <div className="notif-box">
            <i className="fa-solid fa-bell-slash"></i>

            <div className="notif-text">
                <p>Get Notified of New Messages</p>
                <span>Turn on Desktop Notifications &gt;</span>
            </div>

            <i className="fa-solid fa-xmark"></i>
        </div>
    );
};

const SearchBar = () => {
    return (
        <div className="search-container">
            <div className="input">
                <i className="fa-solid fa-magnifying-glass"></i>
                <input type="text" placeholder="Search or start new chat" />
            </div>

            <i className="fa-sharp fa-solid fa-bars-filter"></i>
        </div>
    );
};

export default App;
